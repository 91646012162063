import React from 'react';
import { Link, PageProps } from 'gatsby';
import BareLayout from '@/components/BareLayout';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const TermsOfService: React.FC<PageProps> = () => (
   <BareLayout pageTitle="Terms of Service">
      <div className="container legal-info">
         <h1>Terms of Service</h1>
         <p><strong>PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY CLICKING “ACCEPTED AND AGREED TO,” OR ITS EQUIVALENT, CUSTOMER AGREES TO THESE TERMS AND CONDITIONS.</strong></p>
         <p>These Terms of Service constitute an agreement (this <strong>“Agreement”</strong>) by and between Lingco Language Labs, Inc., a Delaware corporation (<strong>“Lingco”</strong>) and you, an individual executing this Agreement (<strong>“Customer”</strong>).</p>
         <p>Lingco provides a website and software platform (the <strong>“Software”</strong>) which consists of technology hosted on Lingco’s computers and accessed remotely, via the cloud. This Agreement is effective as of the date Customer clicks “Accepted and Agreed To” or its equivalent (the <strong>“Effective Date”</strong>). Customer’s use of and Lingco’s provision of Lingco’s Software are governed by this Agreement.</p>
         <p>Therefore, in consideration for the commitments set forth below, the adequacy of which consideration the parties hereby acknowledge, the parties agree as follows.</p>
         <p>EACH PARTY ACKNOWLEDGES THAT IT HAS READ THIS AGREEMENT, UNDERSTANDS IT, AND AGREES TO BE BOUND BY ITS TERMS. THE PERSON EXECUTING THIS AGREEMENT ON CUSTOMER’S BEHALF REPRESENTS THAT HE OR SHE HAS THE AUTHORITY TO BIND CUSTOMER TO THESE TERMS AND CONDITIONS.</p>

         <ol>y
            <li><h2>Definitions</h2>
               <p>The following capitalized terms will have the following meanings whenever used in this Agreement.</p>
               <ol>
                  <li><strong>“AUP”</strong> means Lingco’s acceptable use policy currently posted at www.lingco.io/acceptable_use.</li>
                  <li><strong>“Customer Data”</strong> means all information of a Customer processed or stored through the Software.</li>
                  <li><strong>“Documentation”</strong> means Lingco's standard manual and documentation related to use of the Software.</li>
                  <li><strong>“Order”</strong> means an order for access to the Software, executed as follows appropriate and relevant to each Customer.</li>
                  <li><strong>“Privacy Policy”</strong> means Lingco’s privacy policy, currently posted at www.lingco.io/privacy_policy</li>
                  <li><strong>“User”</strong> means the Customer or any individual who uses the Software on Customer’s behalf or through Customer’s account or passwords, whether authorized or not.</li>
               </ol>
            </li>
            <li><h2>The Software</h2>
               <ol>
                  <li><strong>Use of the Software.</strong> During the Term, Customer may access and use the Software pursuant to the terms of any outstanding Order, including such features and functions as the Order requires.</li>
                  <li><strong>Service Level Applicable to Users.</strong> Lingco will use reasonable efforts to provide the Software and respond to User inquiries. Lingco provides no remedies to a User for any failure to meet this service level. Lingco is not required to issue refunds or to make payments after a failure to meet the service level required under this section under any circumstances, including without limitation after termination of this Agreement.</li>
                  <li><strong>Documentation:</strong> Customer may reproduce and use the Documentation solely as necessary to support Users’ use of the Software.</li>
                  <li><strong>Software Revisions</strong>. Lingco may revise Software features and functions at any time, including without limitation by removing such features and functions or reducing service levels.</li>
               </ol>
            </li>
            <li><h2>Payment</h2>
               <ol>
                  <li><strong>Subscription Fees.</strong> Customer will pay Lingco the fee set forth in each Order, if any (the <strong>“Subscription Fee”</strong>), for each Term. Lingco’s invoices are due within 45 days of receipt of the invoice. Lingco will not be required to refund the Subscription Fee under any circumstances.</li>
                  <li><strong>Taxes.</strong> Amounts due under this Agreement are payable to Lingco without deduction and are net of any tax, tariff, duty, or assessment imposed by any government authority (national, state, provincial, or local), including without limitation any sales, use, excise, ad valorem, property, withholding, or value added tax withheld at the source. If applicable law requires withholding or deduction of such taxes or duties, Customer will separately pay Lingco the withheld or deducted amount. However, the prior two sentences do not apply to taxes based on Lingco’s net income.</li>
               </ol>
            </li>

            <li><h2>Customer Data &amp; Privacy.</h2>
               <ol>
                  <li><strong>Privacy Policy.</strong> The Privacy Policy applies only to the Software and does not apply to any third party website or service linked to the Software or recommended or referred to through the Software or by Lingco’s staff.</li>
                  <li><strong>Risk of Exposure. </strong>Customer recognizes and agrees that hosting data online involves risks of unauthorized disclosure or exposure and that, in accessing and using the Software, Customer assumes such risks. Lingco offers no representation, warranty, or guarantee that Customer Data will not be exposed or disclosed through errors or the actions of third parties.</li>
                  <li><strong>Data Accuracy.</strong> Lingco will have no responsibility or liability for the accuracy of data uploaded to the Software by Customer, including without limitation Customer Data and any other data uploaded by Users.</li>
                  <li><strong>Data Deletion.</strong> Lingco may permanently erase Customer Data if Customer’s account is  terminated for 60 days or more.</li>

                  <p><strong>Excluded Data:</strong>. Customer represents and warrants that Customer Data does not and will not include, and Customer has not and will not upload or transmit to Lingco's computers or other media, any data (<strong>“Excluded Data”</strong>) regulated pursuant to HIPAA, Gramm-Leach-Bliley Act, federal financial services laws, and PCI Compliance (the "Excluded Data Laws"). CUSTOMER RECOGNIZES AND AGREES THAT: (a) LINGCO HAS NO LIABILITY FOR ANY FAILURE TO PROVIDE PROTECTIONS SET FORTH IN THE EXCLUDED DATA LAWS OR OTHERWISE TO PROTECT EXCLUDED DATA; AND (b)LINGCO’S SOFTWARES ARE NOT INTENDED FOR MANAGEMENT OR PROTECTION OF EXCLUDED DATA AND MAY NOT PROVIDE ADEQUATE OR LEGALLY REQUIRED SECURITY FOR EXCLUDED DATA.</p>

                  <p>Performance of services under the Agreement may entail the disclosure to Lingco of personally identifiable information from student education records protected by the Family Educational Rights and Privacy Act (FERPA) ("Student Information"). Lingco acknowledges that for the purposes of the agreement, it will be designated as a “school official” with “legitimate educational interests” in the “Student Information,” as those terms have been defined under FERPA and its implementing regulations, and Lingco agrees to abide by the limitations and requirements imposed by 34 CFR 99.33(a) on school officials. Lingco will use Student Information only for the specific purpose of fulfilling its obligations under the Agreement. Lingco may not disclose or share any Student Information to any other party or for any other purpose without the prior written consent of the student. By way of illustration and not of limitation, Lingco will not use such information for Lingco’s own benefit and, in particular, will not engage in “data mining” of customer data or communications, whether through automated or human means, except as necessary to fulfill its obligations under the Agreement. The provisions of this section will be applicable to any subcontractors or agents to whom Lingco may release Student Information in order to perform services under the Agreement. Lingco agrees that prior to releasing any Student Information to any subcontractor or agent, Lingco will require such subcontractor or agent to acknowledge its obligations as a "school official" and to agree to comply with the FERPA requirements articulated in this section. Improper re-disclosure of Student Information can result in Lingco being denied access to such information for at least 5 years. Lingco shall return or certify destruction of all Student Information (including that provided to or obtained by its subcontractors or agents) upon termination of the Agreement.</p>
                  <li><strong>Aggregate &amp; Anonymized Data.</strong> Notwithstanding the provisions above of this Article 4, Lingco may use, reproduce,  or exploit De-Identified Data  solely to advance the development of the Software, including through internal development or research. (“De-Identified Data” refers to Customer Data with the following removed: information that identifies or could reasonably be used to identify an individual person, a household, or Customer.)</li>
               </ol>
            </li>
            <li><h2>CUSTOMER’S RESPONSIBILITIES &amp; RESTRICTIONS.</h2>
               <ol>
                  <li><strong>Acceptable Use.</strong> Customer will comply with the AUP. Customer will not: (a) use the Software for service bureau or time-sharing purposes or in any other way allow third parties to exploit the Software; (b) provide Software passwords or other log-in information to any third party; (c) share non-public Software features or content with any third party; (d) access the Software in order to build a competitive product or service, to build a product using similar ideas, features, functions or graphics of the Software, or to copy any ideas, features, functions or graphics of the Software; or (e) engage in web scraping or data scraping on or related to the Software, including without limitation collection of information through any software that simulates human activity or any bot or web crawler. In the event that it suspects any breach of the requirements of this Section 5.1, including without limitation by Users, Lingco may suspend Customer’s access to the Software without advanced notice, in addition to such other remedies as Lingco may have. Neither this Agreement nor the AUP requires that Lingco take any action against Customer or any User or other third party for violating the AUP, this Section 5.1, or this Agreement, but Lingco is free to take any such action it sees fit.</li>
                  <li><strong>Unauthorized Access.</strong> Customer will take reasonable steps to prevent unauthorized access to the Software, including without limitation by protecting its passwords and other log-in information. Customer will notify Lingco immediately of any known or suspected unauthorized use of the Software or breach of its security and will use best efforts to stop said breach.</li>
                  <li><strong>Compliance with Laws.</strong> In its use of the Software, Customer will comply with all applicable laws, including without limitation laws governing the protection of personally identifiable information and other laws applicable to the protection of Customer Data.</li>
                  <li><strong>Users &amp; Software Access.</strong> Customer is responsible and liable for: (a) Users’ use of the Software, including without limitation unauthorized User conduct and any User conduct that would violate the AUP or the requirements of this Agreement applicable to Customer; and (b) any use of the Software through Customer’s account, whether authorized or unauthorized.</li>
               </ol>
            </li>
            <li><h2>IP &amp; Feedback</h2>
               <ol>
                  <li><strong>IP Rights to the Software.</strong> Lingco retains all right, title, and interest in and to the Software, including without limitation all software used to provide the Software and all graphics, user interfaces, logos, and trademarks reproduced through the Software. This Agreement does not grant Customer any intellectual property license or rights in or to the Software or any of its components. Customer recognizes that the Software and its components are protected by copyright and other laws.</li>
                  <li><strong>Feedback.</strong> Lingco has not agreed to and does not agree to treat as confidential any Feedback (as defined below) Customer or Users provide to Lingco, and nothing in this Agreement or in the parties’ dealings arising out of or related to this Agreement will restrict Lingco’s right to use, profit from, disclose, publish, keep secret, or otherwise exploit Feedback, without compensating or crediting Customer or the User in question. Feedback will not be considered confidential. (“Feedback” refers to any suggestion or idea for improving or otherwise modifying any of Lingco’s products or services.)</li>
               </ol>
            </li>
            <li><h2>REPRESENTATIONS &amp; WARRANTIES</h2>
               <ol>
                  <li><strong>From Customer.</strong> Customer represents and warrants that: (a) it has the full right and authority to enter into, execute, and perform its obligations under this Agreement and that no pending or threatened claim or litigation known to it would have a material adverse impact on its ability to perform as required by this Agreement; and (b) it has accurately identified itself and it has not provided any inaccurate information about itself to or through the Software.</li>
                  <li><strong>Warranty Disclaimers.</strong> You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website or Software will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR SOFTWARE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SOFTWARE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE OR SOFTWARE LINKED TO IT.</li>

                  <p>YOUR USE OF THE WEBSITE OR SOFTWARE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SOFTWARE IS AT YOUR OWN RISK. THE WEBSITE OR SOFTWARE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SOFTWARE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE OR SOFTWARE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE OR SOFTWARE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SOFTWARE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR SOFTWARE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SOFTWARE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>

                  <p>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>

                  <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
               </ol>
            </li>
            <li><h2>Indemnification.</h2>
               <p>Customer agrees to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to Customer’s violation of this Agreement</p>
            </li>
            <li><h2>Limitation of Liability.</h2>
               <ol>
                  <li><strong>General.</strong> TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH CUSTOMER’S USE, OR INABILITY TO USE, THE WEBSITE OR SOFTWARE, ANY WEBSITE OR SOFTWARES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SOFTWARE OR SUCH OTHER WEBSITE OR SOFTWARES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</li>
                  <li><strong>Dollar Cap.</strong> THE PARTIES’ CUMULATIVE LIABILITY FOR ALL CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT WILL NOT EXCEED TWO TIMES (2X) THE FEES PAID PURSUANT TO THE APPLICABLE ORDER PRECEDING THE INJURY IN QUESTION, FOR ALL CLAIMS ARISING OUT OF OR RELATED TO SUCH ORDER.</li>
               </ol>
            </li>
            <li><h2>Limitation on Time to File Claims.</h2>
               <p>ANY CAUSE OF ACTION OR CLAIM CUSTOMER MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE WEBSITE OR SOFTWARE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER CUSTOMER KNOWS, OR SHOULD KNOW, OF THE EXISTENCE OF ALL FACTS NECESSARY TO GIVE RISE TO THE CAUSE OF ACTION OR CLAIM; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED</p>
            </li>
            <li><h2>FERPA Compliance</h2>
               <p>Our company is committed to protecting the privacy of student education records and strictly adheres to the requirements of the Family Educational Rights and Privacy Act (FERPA). Any and all education records or personally identifiable information (PII) of students shared with or accessed by our platform are treated with utmost confidentiality and will not be disclosed to third parties without explicit consent, except as allowed by FERPA regulations. We employ appropriate technical and organizational measures to safeguard student information and to prevent unauthorized access or disclosure.</p>
            </li>
            <li><h2>COPPA Compliance</h2>
               <p>We recognize the importance of safeguarding children's online privacy. In compliance with the Children's Online Privacy Protection Act (COPPA), we do not knowingly collect, use, or disclose personal information from children under the age of 13 without obtaining verifiable parental consent. Our platform has implemented strict measures to ensure that any user under the age of 13 does not provide personal information without necessary parental permissions. Parents or guardians have the right to review and request the deletion of their child's personal information and can refuse any further collection or use of their child's information.</p>
            </li>
            <li><h2>Miscellaneous</h2>
               <ol>
                  <li><strong>Independent Contractors.</strong> The parties are independent contractors and will so represent themselves in all regards. Neither party is the agent of the other, and neither may make commitments on the other’s behalf.</li>
                  <li><strong>Notices.</strong> Lingco may send notices pursuant to this Agreement to Customer’s email contact points provided by Customer, and such notices will be deemed received 24 hours after they are sent. Customer may send notices pursuant to this Agreement to <a href="mailto:policies@lingco.io">policies@lingco.io</a>, and such notices will be deemed received 72 hours after they are sent. In addition, Customer is on notice and agrees that: (a) for claims of copyright infringement, the complaining party may contact <a href="mailto:policies@lingco.io">policies@lingco.io</a>; and (b) Lingco will terminate the accounts of Users who are repeat copyright infringers.</li>
                  <li><strong>Force Majeure.</strong> No delay, failure, or default, other than a failure to pay fees when due, will constitute a breach of this Agreement to the extent caused by acts of war, terrorism, hurricanes, earthquakes, other acts of God or of nature, strikes or other labor disputes, riots or other acts of civil disorder, embargoes, or other causes beyond the performing party’s reasonable control.</li>
                  <li><strong>Assignment &amp; Successors.</strong> Unless in the event of an merger, acquisition, or a sale of substantially all of its assets, neither party may assign this Agreement or any of its rights or obligations hereunder without the other’s express written consent. Except to the extent forbidden in this Section 12.4, this Agreement will be binding upon and inure to the benefit of the parties’ respective successors and assigns.</li>
                  <li><strong>Severability.</strong> To the extent permitted by applicable law, the parties hereby waive any provision of law that would render any clause of this Agreement invalid or otherwise unenforceable in any respect. In the event that a provision of this Agreement is held to be invalid or otherwise unenforceable, such provision will be interpreted to fulfill its intended purpose to the maximum extent permitted by applicable law, and the remaining provisions of this Agreement will continue in full force and effect.</li>
                  <li><strong>No Waiver.</strong> Neither party will be deemed to have waived any of its rights under this Agreement by lapse of time or by any statement or representation other than by an authorized representative in an explicit written waiver. No waiver of a breach of this Agreement will constitute a waiver of any other breach of this Agreement.</li>
                  <li><strong>Choice of Law &amp; Jurisdiction:</strong> This Agreement and all claims arising out of or related to this Agreement will be governed solely by the internal laws of the State of Michigan, including without limitation applicable federal law, without reference to: (a) any conflicts of law principle that would apply the substantive laws of another jurisdiction to the parties’ rights or duties; (b) the 1980 United Nations Convention on Contracts for the International Sale of Goods; or (c) other international laws. The parties consent to the personal and exclusive jurisdiction of the federal and state courts of Ingham County, Michigan. This Section 12.7 governs all claims arising out of or related to this Agreement, including without limitation tort claims.</li>
                  <li><strong>Conflicts.</strong> In the event of any conflict between this Agreement and any Lingco policy posted online, including without limitation the AUP or Privacy Policy, the terms of this Agreement will govern.</li>
                  <li><strong>Technology Export.</strong> Customer will not: (a) permit any third party to access or use the Software in violation of any U.S. law or regulation; or (b) export any software provided by Lingco or otherwise remove it from the United States except in compliance with all applicable U.S. laws and regulations. Without limiting the generality of the foregoing, Customer will not permit any third party to access or use the Software in, or export such software to, a country subject to a United States embargo (as of the Effective Date, Cuba, Iran, North Korea, Sudan, and Syria).</li>
                  <li><strong>Entire Agreement.</strong> This Agreement sets forth the entire agreement of the parties and supersedes all prior or contemporaneous writings, negotiations, and discussions with respect to its subject matter. Neither party has relied upon any such prior or contemporaneous communications.</li>
                  <li><strong>Amendment.</strong> Lingco may amend this Agreement from time to time by posting an amended version at its Website and sending Customer written notice thereof. Customer’s continued use of the Service thereafter will confirm Customer’s consent thereto. This Agreement may not be amended in any other way except through a written agreement by authorized representatives of each party. Lingco may revise the Privacy Policy and Acceptable Use Policy at any time by posting a new version of either, and such new version will become effective on the date it is posted.</li>
               </ol>
            </li>
         </ol>

         <p><strong>Customer’s Comments and Concerns.</strong> This Website or Software is operated by Lingco Language Labs, Inc., 1146 South Washington Square, Lansing, Michigan 48910. All other feedback, comments, requests for technical support, and other communications relating to the Website or Software should be directed to: <a href="">support@lingco.io.</a></p>

         <h2>Revision</h2>
         <p>Posted/Revised: 2020 October</p>
      </div>
   </BareLayout >
);

export default TermsOfService;
